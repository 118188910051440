// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import {
    DapDocumentContent, DapDocumentContentDap, DapDocumentContentField, DapDocumentContentFieldValue, DapDocumentContentSection, DapDocumentContentTable
} from "@/_models/dap-document-details";
import { DapDocContainerDiff, DapDocDiff, DapDocFieldDiff, DapDocRowDiff, DapDocSectionDiff, DapDocTableDiff } from "../vm";

export class DapDocDiffHelper {
    public static getDiff(doc1: DapDocumentContent, doc2: DapDocumentContent): DapDocDiff {
        const result = new DapDocDiff();
        result.fieldsDiff = DapDocDiffHelper.getFieldsDiff(doc1.fields, doc2.fields);
        result.tablesDiff = DapDocDiffHelper.getTablesDiff(doc1.tables, doc2.tables);
        result.sectionsDiff = DapDocDiffHelper.getSectionsDiff(doc1.sections, doc2.sections);
        return result;
    }

    private static getFieldsDiff(fields1: DapDocumentContentField[], fields2: DapDocumentContentField[]): DapDocFieldDiff[] {
        const result: DapDocFieldDiff[] = [];

        fields1?.forEach(field1 => {
            const field2 = fields2?.find(x => field1.key === x.key);
            const fieldDiff = DapDocDiffHelper.getFieldDiff(field1, field2);
            if (fieldDiff != null) {
                const diff = new DapDocFieldDiff();
                diff.key = field1.key;
                diff.doc1Value = fieldDiff[0];
                diff.doc2Value = fieldDiff[1];
                result.push(diff);
            }
        });

        fields2?.forEach(field2 => {
            if (fields1 == null || fields1.some(x => x.key === field2.key) === false) {
                const fieldDiff = DapDocDiffHelper.getFieldDiff(undefined, field2);
                if (fieldDiff != null) {
                    const diff = new DapDocFieldDiff();
                    diff.key = field2.key;
                    diff.doc1Value = fieldDiff[0];
                    diff.doc2Value = fieldDiff[1];
                    result.push(diff);
                }
            }
        })

        return result;
    }

    private static getFieldDiff(field1: DapDocumentContentFieldValue, field2: DapDocumentContentFieldValue): [any, any] {
        if ((field1?.str != null || field2?.str != null) && field1?.str !== field2?.str) {
            return [field1?.str, field2?.str];
        }
        if ((field1?.text != null || field2?.text != null) && field1?.text !== field2?.text) {
            return [field1?.text, field2?.text];
        }
        if ((field1?.int != null || field2?.int != null) && field1?.int !== field2?.int) {
            return [field1?.int, field2?.int];
        }
        if ((field1?.float != null || field2?.float != null) && field1?.float !== field2?.float) {
            return [field1?.float, field2?.float];
        }
        if ((field1?.datetime != null || field2?.datetime != null) && field1?.datetime !== field2?.datetime) {
            return [field1?.datetime, field2?.datetime];
        }
        if ((field1?.measure != null || field2?.measure != null)
            && (field1?.measure?.value !== field2?.measure?.value || field1?.measure?.uom !== field2?.measure?.uom)) {
            return [field1?.measure, field2?.measure];
        }
        if ((field1?.bool != null || field2?.bool != null) && field1?.bool !== field2?.bool) {
            return [field1?.bool, field2?.bool];
        }

        return null;
    }

    private static getTablesDiff(tables1: DapDocumentContentTable[], tables2: DapDocumentContentTable[]): DapDocTableDiff[] {
        const result: DapDocTableDiff[] = [];

        tables1?.forEach(table1 => {
            const table2 = tables2?.find(x => x.key === table1.key);
            if (table2 != null) {
                const tableDiff = new DapDocTableDiff();
                tableDiff.key = table1.key;
                const rowsDiff: DapDocRowDiff[] = [];
                for (let rowIndex = 0; rowIndex < table1.rows.length || rowIndex < table2.rows.length; rowIndex++) {
                    if (table1.rows[rowIndex] == null) {
                        const rowDiff = new DapDocRowDiff();
                        rowDiff.index = rowIndex;
                        rowDiff.onlyInDoc1 = true;
                        rowsDiff.push(rowDiff);
                    } else if (table2.rows[rowIndex] == null) {
                        const rowDiff = new DapDocRowDiff();
                        rowDiff.index = rowIndex;
                        rowDiff.onlyInDoc2 = true;
                        rowsDiff.push(rowDiff);
                    } else {
                        const fieldsDiff: DapDocFieldDiff[] = [];
                        for (let colIndex = 0; colIndex < table1.columns.length; colIndex++) {
                            const fieldDiff = DapDocDiffHelper.getFieldDiff(table1.rows[rowIndex][colIndex], table2.rows[rowIndex][colIndex]);
                            if (fieldDiff != null) {
                                const diff = new DapDocFieldDiff();
                                diff.key = table1.columns[colIndex];
                                diff.doc1Value = fieldDiff[0];
                                diff.doc2Value = fieldDiff[1];
                                fieldsDiff.push(diff);
                            }
                        }

                        if (fieldsDiff.length > 0) {
                            const rowDiff = new DapDocRowDiff();
                            rowDiff.index = rowIndex;
                            rowDiff.fieldsDiff = fieldsDiff;
                            rowsDiff.push(rowDiff);
                        }
                    }
                }
                tableDiff.rowsDiff = rowsDiff;
                result.push(tableDiff);
            }
        });

        return result;
    }

    private static getSectionsDiff(doc1Sections: DapDocumentContentSection[], doc2Sections: DapDocumentContentSection[]): DapDocSectionDiff[] {
        const result: DapDocSectionDiff[] = [];

        doc1Sections?.forEach(doc1Section => {
            const diff = new DapDocSectionDiff();
            diff.key = doc1Section.key;

            const doc2Section = doc2Sections?.find(x => x.key === doc1Section.key);
            if (doc2Section != null) {
                diff.fieldsDiff = DapDocDiffHelper.getFieldsDiff(doc1Section.fields, doc2Section.fields);
                diff.tablesDiff = DapDocDiffHelper.getTablesDiff(doc1Section.tables, doc2Section.tables);
                diff.dapsDiff = DapDocDiffHelper.getDapsDiff(doc1Section.daps, doc2Section.daps);
            } else {
                diff.onlyInDoc1 = true;
            }
            
            result.push(diff);
        });

        doc2Sections?.forEach(doc2Section => {
            if (doc1Sections == null || doc1Sections.some(x => x.key === doc2Section.key) === false) {
                const diff = new DapDocSectionDiff();
                diff.key = doc2Section.key;
                diff.onlyInDoc2 = true;
                result.push(diff);
            }
        });

        return result;
    }

    private static getDapsDiff(doc1Daps: DapDocumentContentDap[], doc2Daps: DapDocumentContentDap[]): DapDocContainerDiff[] {
        const result: DapDocContainerDiff[] = [];

        doc1Daps?.forEach(doc1Dap => {
            const diff = new DapDocContainerDiff();
            diff.key = doc1Dap.key;

            const doc2Dap = doc2Daps?.find(x => x.key === doc1Dap.key);
            if (doc2Dap != null) {
                diff.fieldsDiff = DapDocDiffHelper.getFieldsDiff(doc1Dap.fields, doc2Dap.fields);
                diff.tablesDiff = DapDocDiffHelper.getTablesDiff(doc1Dap.tables, doc2Dap.tables);
            } else {
                diff.onlyInDoc1 = true;
            }
            
            result.push(diff);
        });

        doc2Daps?.forEach(doc2Dap => {
            if (doc1Daps == null || doc1Daps.some(x => x.key === doc2Dap.key) === false) {
                const diff = new DapDocContainerDiff();
                diff.key = doc2Dap.key;
                diff.onlyInDoc2 = true;
                result.push(diff);
            }
        });

        return result;
    }
}
